<template>
  <div class="contacts">
    <div class="container">
      <div class="contacts__nav">
        <locale-router-link to="/" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <a class="crumbs-link contacts-link"> {{ $t("contacts") }}</a>
      </div>

      <h1 class="contacts__title">{{ $t("contacts") }}</h1>

      <div class="contacts__items">
        <div class="contacts__column">
          <!-- Головной офис -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">{{ $t("headOfficeTitle") }}</h3>
            <address class="contacts__address">
              {{ $t("mainAddress") }}
            </address>
          </div>
          <!-- MagnumOpt -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">MagnumOpt</h3>
            <div class="contacts__item-content">
              <a href="tel:998900541255" class="contacts__phone">
                +998 90 054 12 55
              </a>
              <a href="mailto:kp@magnum.kz" class="contacts__suggestion-email">
                Rozametov@magnum.kz
              </a>
              <p>
                <span>Telegram</span>
                <a
                  target="_blank"
                  class="contacts__suggestion-email"
                  href="https://t.me/MagnumGo_bot"
                >
                  @magnumoptuz_bot</a
                >
              </p>
            </div>
          </div>
          <!-- MagnumOpt -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">{{ $t("rent") }}</h3>
            <div class="contacts__item-content">
              <a href="tel:998903711562" class="contacts__phone">
                +99890 054 42 50
              </a>
              <p v-html="$t('rentDate')"></p>
              <!-- <a
                href="mailto:n.petrosyan@magnumgo.uz"
                class="contacts__suggestion-email"
              >
                n.petrosyan@magnumgo.uz
              </a> -->
            </div>
          </div>
          <!-- HR Magnum Retail -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">HR Magnum Retail</h3>
            <div class="contacts__item-content">
              <p class="contacts__text">
                {{ $t("contactTelegram") }}
                <a
                  href="https://t.me/mgmuz_bot"
                  target="_blank"
                  class="contacts__suggestion-email"
                >
                  @magnum_jbot
                </a>
                <br />
                + 998 (90) 054 65 45 <br />
              </p>
            </div>
          </div>
        </div>

        <div class="contacts__column">
          <!-- Контактный центр -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">{{ $t("contactCenterTitle") }}</h3>
            <a href="tel:998555000066" class="contacts__phone">
              +998 55 500-00-66
            </a>
          </div>

          <!-- Реквизиты  -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">{{ $t("requisites") }}</h3>
            <p class="contacts__text">
              {{ $t("legalAddress") }}
              <br />
              {{ $t("rs") }} 20208000605279472001 <br />
              {{ $t("inn") }} 307716344 <br />{{ $t("nationalBank") }}<br />{{
                $t("inn")
              }}
              207297973
            </p>
          </div>
          <!-- Для поставщиков -->
          <div class="contacts__item">
            <h3 class="contacts__item-title">{{ $t("forSuppliers") }}</h3>
            <div class="contacts__item-content">
              <a
                href="mailto:kp@magnumgo.uz"
                class="contacts__suggestion-email"
              >
                kp@magnumgo.uz
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      isShowComplianceForm: false,
    };
  },
  components: {
    // ComplianceForm: () => import("@/components/compliance/ComplianceForm.vue"),
  },
};
</script>
